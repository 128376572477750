import React from "react"
import Layout from "../components/Layout"
import "../css/projects/py_proj1.css"
import { Link } from "gatsby"
import { FaCode } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const Py_proj1 = () => {
  return (
    <Layout>
      <SEO title="Egg Catcher Game" description="Awesome python project" />
      <Particle8></Particle8>
      <h6>s</h6>
      <section className="noSelect">
        <Link to="/pythonprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>
      <section className="proj-name-wrap noSelect">
        <div className="pyt-img-1">
          <div className="py-1-heading">
            <h1>Egg Catcher Game</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Egg%20Catcher%20Game"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>

        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It :</h3>
            <p>
              Simple and amazing game. <br />
              You got 3 lives, and a bowl, if you drop a egg you lose a life.{" "}
              <br />
              Eggs will fall from sky continously.
              <br /> If you catch an egg , your score is ready in front of you.
              <br />
              Your bowl can be moved left or right using keyboard input.
              <br /> It has Beautiful interface with colourful eggs.
              <br />
              Do not worry about the slow speed of falling eggs, its just a
              start, the speed will soon increase and yes, you will ultimately
              out of lives at a point.
              <br />
              <br />
              Well, my higest score is 310. Lets see if you can break it...
            </p>

            <h3>Skills Behind It :</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>Tkinter</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Canvas</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Itertools</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Random</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Python</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Py_proj1
